html, body {
  font-family: $default-font;
  height: 100%;
  line-height: pxtoem($default-line-height);
}

a {
  text-decoration: none;
}

li.node-readmore a:after {
  @include icomoon('caret-right');

  font-size: 50%;
  vertical-align: 1px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $default-font;
  margin: 0 0 12px;
}

div.streamers {
  clear: both;
  margin: 0 auto;
}

header,
div.wrapper,
div.streamers,
footer {
  background: $lochmara;
  border-left: solid 1px $athensgrey;
  border-right: solid 1px $athensgrey;
  position: relative;
  //box-shadow: 0px 0px 25px 0px rgba(0,0,0,.75);
}

div.streamers,
.logowrap {
  @include flex('wrap', 'space-between', 'stretch');
  margin: 0 auto;
}

section.content {
  @include flex('wrap', 'space-between', 'stretch');
  margin: 45px 0;
  position: relative;

  .content-wrapper {
    background: $silver;
    margin: 0 45px 0 25px;
    padding: 45px;
    width: 100%;
  }

}
