.paragraphs-item-3-images {

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .titel {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      //min-width: 475px;
      width: 100%;
    }

    div.multi-images {
      display: flex;
      justify-content: space-between;
      width: 100%;

      a {
        display: block;
        margin-bottom: 22px;
      }
    }
  }
}
