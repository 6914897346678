.logowrap {
  //background: rgba(255, 255, 255, 0.5);
  //height: 71px;
  margin-top: -209px;
  position: relative;

  .logo {
    line-height: 0;
  }

}

.name-slogan {
  background: transparentize($black, .5);
  font-family: 'Quicksand Light Regular';
  font-size: 3em;
  line-height: 71px;
  text-align: center;
  text-transform: uppercase;

  a {
    color: $white;
  }
}
