.paragraphs-item-afbeelding-plus-tekst {

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .titel {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      width: 100%;
    }

    div.image {
      display: inline-block;
      flex: 1;
      margin: 0 22px 22px 0;
    }

    div.tekst {
      display: inline-block;
      flex: 3;
     }
  }

  &.img_left {

    .content div.tekst {
      order: 2;
    }

    .content div.image {
      order: 1;
    }
  }

  &.img_right {

    .content div.tekst {
      order: 1;
    }

    .content div.image {
      margin: 0 0 22px 22px;
      order: 2;
    }
  }
}
