.paragraphs-item-2-images {

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .titel {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      //min-width: 475px;
      width: 100%;
    }

    div.image {
      display: inline-block;
      flex: 1;
      margin: 0 22px 22px 0;
    }

    div.promo {
      display: inline-block;
      flex: 1;
      margin: 0;
    }


  }

  &.img_left {

    .content div.promo {
      order: 2;
    }

    .content div.image {
      order: 1;
    }
  }

  &.img_right {

    .content div.promo {
      margin: 0 22px 22px 0;
      order: 1;
    }

    .content div.image {
      margin: 0;
      order: 2;
    }
  }
}
