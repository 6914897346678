.node-type-fotoalbums {
  .content {
    .image {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -13px;

      a {
        margin: 13px;
      }
    }

  }
}
