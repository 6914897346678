footer {
  background: $tundora;
  height: 64px;

  p {
    color: $linkwater;
    line-height: 64px;
    margin: 0;
    text-align: center;

    a.insta {
      color: $linkwater;

      &:before {
        @include icomoon('insta');
        font-size: 16px;
        padding: 0 7px;
      }
    }
  }
}
