.front {
  section.content {
    padding: 5px;

    .views-field-title {
      display: none;
    }

    h2 {
      font-weight: 100;
    }
  }
}
