@charset "UTF-8";
.tabs ul.primary:after,
.tabs-container ul.primary:after {
  clear: both;
  content: '';
  display: table; }

html.js fieldset.collapsible .fieldset-legend:before, div.messages.warning:before, div.messages.status:before, div.messages.error:before, input[type='radio'] + label:before, input[type='checkbox'] + label:before, input[type='checkbox']:checked + label:before, input[type='radio']:checked + label:before, label.select.prettify span:after, ol li:before, li.node-readmore a:after, footer p a.insta:before, body.ft-tabs-rendered div.ft-collapsible-tabs .ft-tab-clicker:before {
  font-family: 'icomoon';
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none; }

*, *:before, *:after {
  box-sizing: border-box; }

ul {
  list-style: inside;
  margin: 0;
  padding: 0; }
  ul.menu {
    list-style: inside; }
  ul li {
    list-style-image: none;
    list-style-type: disc; }
    ul li.leaf, ul li.collapsed {
      list-style-image: none;
      list-style-type: disc; }

.element-invisible {
  display: table-column;
  position: relative !important; }

body {
  color: #3d5573;
  font-size: 16px;
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  background-color: #eceff4;
  color: #3d5573;
  font-family: sans-serif; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 32px;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

p {
  margin: 1em 0; }

dfn {
  font-style: italic; }

hr {
  box-sizing: content-box;
  height: 0; }

mark {
  background-color: #ffff00;
  color: #000000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 16px; }

pre {
  white-space: pre-wrap; }

q {
  quotes: '“' '”' '‘' '’'; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  display: block; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #f0f3f7;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type='button'], input[type='reset'], input[type='submit'] {
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='search'] {
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-light-webfont.eot");
  src: url("../fonts/titilliumweb-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-light-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-light-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-light-webfont.svg#titillium_weblight") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-regular-webfont.eot");
  src: url("../fonts/titilliumweb-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-regular-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-regular-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-regular-webfont.svg#titillium_webregular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-bold-webfont.eot");
  src: url("../fonts/titilliumweb-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-bold-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-bold-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-bold-webfont.svg#titillium_webbold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-lightitalic-webfont.eot");
  src: url("../fonts/titilliumweb-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-lightitalic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-lightitalic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-lightitalic-webfont.svg#titillium_weblight_italic") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-italic-webfont.eot");
  src: url("../fonts/titilliumweb-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-italic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-italic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-italic-webfont.svg#titillium_webitalic") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Titillium";
  src: url("../fonts/titilliumweb-bolditalic-webfont.eot");
  src: url("../fonts/titilliumweb-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-bolditalic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-bolditalic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-bolditalic-webfont.svg#titillium_webbold_italic") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff") format("application/font-woff"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Quicksand Book Oblique Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Book Oblique Regular"), url("../fonts/Quicksand_Book_Oblique.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Book Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Book Regular"), url("../fonts/Quicksand_Book.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Dash Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Dash Regular"), url("../fonts/Quicksand_Dash.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Light Oblique Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Light Oblique Regular"), url("../fonts/Quicksand_Light_Oblique.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Light Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Light Regular"), url("../fonts/Quicksand_Light.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Bold Oblique Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Bold Oblique Regular"), url("../fonts/Quicksand_Bold_Oblique.woff") format("woff"); }

@font-face {
  font-family: 'Quicksand Bold Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Bold Regular"), url("../fonts/Quicksand_Bold.woff") format("woff"); }

div#svg-grid-background,
div.responsive-identifier {
  display: none; }

body.show-responsive-identifier div.responsive-identifier {
  bottom: 0;
  display: block;
  font-weight: bold;
  left: 0;
  padding: 4px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10; }

body.show-grid div#svg-grid-background {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }
  body.show-grid div#svg-grid-background > svg {
    display: block;
    margin: 0 auto; }
    @media screen and (min-width: 1248px) {
      body.show-grid div#svg-grid-background > svg rect {
        fill: rgba(192, 57, 43, 0.12); } }
    @media screen and (min-width: 1002px) and (max-width: 1247px) {
      body.show-grid div#svg-grid-background > svg rect {
        fill: rgba(155, 89, 182, 0.12); } }
    @media screen and (min-width: 768px) and (max-width: 1001px) {
      body.show-grid div#svg-grid-background > svg rect {
        fill: rgba(41, 128, 185, 0.12); } }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      body.show-grid div#svg-grid-background > svg rect {
        fill: rgba(39, 174, 96, 0.12); } }
    @media screen and (min-width: 0px) and (max-width: 479px) {
      body.show-grid div#svg-grid-background > svg rect {
        fill: rgba(241, 196, 15, 0.12); } }

body > div.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto; }

body.columns-middle .content.column {
  order: 2; }

body.columns-middle .sidebar.first {
  order: 1; }

body.columns-middle .sidebar.second {
  order: 3; }

body.columns-middle.one-sidebar.sidebar-second .content.column {
  order: 1; }

body.columns-middle.one-sidebar.sidebar-second .sidebar.second {
  order: 2; }

body.columns-left .content.column {
  order: 3; }

body.columns-left .sidebar.first {
  order: 1; }

body.columns-left .sidebar.second {
  order: 2; }

body.columns-right .content.column {
  order: 1; }

body.columns-right .sidebar.first {
  order: 2; }

body.columns-right .sidebar.second {
  order: 3; }

nav,
header,
footer {
  clear: both;
  margin: 0 auto; }

header .logo a {
  display: inline-block;
  width: auto; }

#breadcrumb ol {
  margin: 0;
  padding: 0; }
  #breadcrumb ol li {
    display: inline-block;
    font-size: 100%; }
  #breadcrumb ol li:before {
    content: attr(data-icon);
    margin-left: 5px; }
  #breadcrumb ol li:first-child:before {
    display: none; }

body.show-responsive-identifier .responsive-identifier {
  background: #C0392B; }
  body.show-responsive-identifier .responsive-identifier:after {
    content: "XL - DESKTOP"; }

body.show-grid div#svg-grid-background {
  font-family: "{ 'columnWidth' : 48, 'gutterWidth' : 30, 'columns' : 16, 'masterContextWidth': 1218}"; }
  body.show-grid div#svg-grid-background svg {
    width: 1218px; }

body nav,
body header,
body footer,
body div.streamers,
body div.wrapper {
  width: 1218px; }

body.two-sidebars.columns-middle .content.column,
body.two-sidebars.columns-right .content.column,
body.two-sidebars.columns-left .content.column {
  width: 594px;
  margin-right: 0; }

body.two-sidebars.columns-middle .sidebar.first,
body.two-sidebars.columns-left .sidebar.first,
body.two-sidebars.columns-left .sidebar.second,
body.two-sidebars.columns-right .sidebar.first,
body.two-sidebars.columns-middle .sidebar.second,
body.two-sidebars.columns-right .sidebar.second {
  width: 282px;
  margin-right: 0; }

body.one-sidebar.columns-right .content.column,
body.one-sidebar.columns-middle .content.column,
body.one-sidebar.columns-left .content.column {
  width: 906px;
  margin-right: 0; }

body.one-sidebar.columns-middle .sidebar.second,
body.one-sidebar.columns-middle .sidebar.first,
body.one-sidebar.columns-left .sidebar.first,
body.one-sidebar.columns-left .sidebar.second {
  width: 282px;
  margin-right: 0; }

body.one-sidebar.columns-right .sidebar.first,
body.one-sidebar.columns-right .sidebar.second {
  width: 282px;
  margin-right: 0; }

body.one-sidebar.columns-right.sidebar-first .content.column, body.one-sidebar.columns-middle.sidebar-second .content.column {
  width: 906px;
  margin-right: 0; }

body.one-sidebar.columns-right.sidebar-first .sidebar.second, body.one-sidebar.columns-middle.sidebar-second .sidebar.second {
  width: 282px;
  margin-right: 0; }

body.one-sidebar div.streamer {
  width: 594px;
  margin-right: 0; }

body.one-sidebar div.logo {
  width: 282px;
  margin-right: 0; }

body.one-sidebar div.name-slogan {
  width: 934px; }

body.no-sidebars.columns-middle .content.column,
body.no-sidebars.columns-right .content.column,
body.no-sidebars.columns-left .content.column {
  width: 1218px;
  margin-right: 0; }

@media screen and (min-width: 1002px) and (max-width: 1247px) {
  body.show-responsive-identifier .responsive-identifier {
    background: #9B59B6; }
    body.show-responsive-identifier .responsive-identifier:after {
      content: "L - TABLET LANDSCAPE"; }
  body.layout-adaptive.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 54, 'gutterWidth' : 30, 'columns' : 12, 'masterContextWidth': 1218}"; }
    body.layout-adaptive.show-grid div#svg-grid-background svg {
      width: 978px; }
  body.layout-adaptive nav,
  body.layout-adaptive footer,
  body.layout-adaptive header,
  body.layout-adaptive div.wrapper {
    width: 978px; }
  body.layout-adaptive.two-sidebars.columns-middle .content.column,
  body.layout-adaptive.two-sidebars.columns-right .content.column {
    width: 474px;
    margin-right: 0; }
  body.layout-adaptive.two-sidebars.columns-left .content.column {
    width: 474px;
    margin-right: 0; }
  body.layout-adaptive.two-sidebars.columns-middle .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-left .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-left .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-right .sidebar.first {
    width: 222px;
    margin-right: 0; }
  body.layout-adaptive.two-sidebars.columns-middle .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-right .sidebar.second {
    width: 222px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-right .content.column,
  body.layout-adaptive.one-sidebar.columns-left .content.column {
    width: 642px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-left .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-left .sidebar.first {
    width: 306px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-right.sidebar-first .content.column, body.layout-adaptive.one-sidebar.columns-middle.sidebar-second .content.column {
    width: 642px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-right.sidebar-first .sidebar.second, body.layout-adaptive.one-sidebar.columns-middle.sidebar-second .sidebar.second {
    width: 306px;
    margin-right: 0; }
  body.layout-adaptive.no-sidebars.columns-middle .content.column,
  body.layout-adaptive.no-sidebars.columns-right .content.column,
  body.layout-adaptive.no-sidebars.columns-left .content.column {
    width: 978px;
    margin-right: 0; }
  body.layout-fluid.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 54, 'gutterWidth' : 30, 'columns' : 12, 'masterContextWidth': 1218}"; }
    body.layout-fluid.show-grid div#svg-grid-background svg {
      width: 978px; }
  body.layout-fluid nav,
  body.layout-fluid header,
  body.layout-fluid footer,
  body.layout-fluid div.wrapper {
    width: 98.02956%; }
  body.layout-fluid.two-sidebars.columns-middle .content.column,
  body.layout-fluid.two-sidebars.columns-right .content.column {
    width: 48.46626%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-left .content.column {
    width: 48.46626%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-middle .sidebar.first,
  body.layout-fluid.two-sidebars.columns-left .sidebar.first,
  body.layout-fluid.two-sidebars.columns-left .sidebar.second,
  body.layout-fluid.two-sidebars.columns-right .sidebar.first {
    width: 22.69939%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-middle .sidebar.second,
  body.layout-fluid.two-sidebars.columns-right .sidebar.second {
    width: 22.69939%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right .content.column,
  body.layout-fluid.one-sidebar.columns-middle .content.column,
  body.layout-fluid.one-sidebar.columns-left .content.column {
    width: 65.64417%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-middle .sidebar.second,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.second {
    width: 31.28834%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right .sidebar.first,
  body.layout-fluid.one-sidebar.columns-right .sidebar.second {
    width: 31.28834%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right.sidebar-first .content.column, body.layout-fluid.one-sidebar.columns-middle.sidebar-second .content.column {
    width: 65.64417%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right.sidebar-first .sidebar.second, body.layout-fluid.one-sidebar.columns-middle.sidebar-second .sidebar.second {
    width: 31.28834%;
    margin-right: 0; }
  body.layout-fluid.no-sidebars.columns-middle .content.column,
  body.layout-fluid.no-sidebars.columns-right .content.column,
  body.layout-fluid.no-sidebars.columns-left .content.column {
    width: 100%;
    margin-right: 0; } }

@media screen and (min-width: 768px) and (max-width: 1001px) {
  body.show-responsive-identifier .responsive-identifier {
    background: #2980B9; }
    body.show-responsive-identifier .responsive-identifier:after {
      content: "M - TABLET PORTRAIT"; }
  body.layout-adaptive.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 44, 'gutterWidth' : 20, 'columns' : 12, 'masterContextWidth': 1218}"; }
    body.layout-adaptive.show-grid div#svg-grid-background svg {
      width: 748px; }
  body.layout-adaptive nav,
  body.layout-adaptive header,
  body.layout-adaptive footer,
  body.layout-adaptive div.wrapper {
    width: 748px; }
  body.layout-adaptive.columns-middle .sidebar.first {
    width: 236px;
    margin-right: 0; }
  body.layout-adaptive.columns-left .sidebar.first,
  body.layout-adaptive.columns-left .sidebar.second,
  body.layout-adaptive.columns-right .sidebar.first,
  body.layout-adaptive.columns-right .sidebar.second {
    width: 364px;
    margin-right: 0; }
  body.layout-adaptive.columns-middle .content.column {
    width: 492px;
    margin-right: 0; }
  body.layout-adaptive.columns-right .content.column,
  body.layout-adaptive.columns-middle .sidebar.second,
  body.layout-adaptive.columns-left .content.column {
    width: 748px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-right.sidebar-first .content.column, body.layout-adaptive.one-sidebar.columns-right.sidebar-second .content.column, body.layout-adaptive.one-sidebar.columns-middle.sidebar-first .content.column, body.layout-adaptive.one-sidebar.columns-middle.sidebar-second .content.column {
    width: 492px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-right.sidebar-first .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-right.sidebar-first .sidebar.first, body.layout-adaptive.one-sidebar.columns-right.sidebar-second .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-right.sidebar-second .sidebar.first, body.layout-adaptive.one-sidebar.columns-middle.sidebar-first .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-middle.sidebar-first .sidebar.first, body.layout-adaptive.one-sidebar.columns-middle.sidebar-second .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-middle.sidebar-second .sidebar.first {
    width: 236px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-left .content.column {
    width: 492px;
    margin-right: 0; }
  body.layout-adaptive.one-sidebar.columns-left .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-left .sidebar.second {
    width: 236px;
    margin-right: 0; }
  body.layout-adaptive.no-sidebars.columns-middle .content.column,
  body.layout-adaptive.no-sidebars.columns-right .content.column,
  body.layout-adaptive.no-sidebars.columns-left .content.column {
    width: 748px;
    margin-right: 0; }
  body.layout-fluid.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 44, 'gutterWidth' : 20, 'columns' : 12, 'masterContextWidth': 1218}"; }
    body.layout-fluid.show-grid div#svg-grid-background svg {
      width: 748px; }
  body.layout-fluid nav,
  body.layout-fluid header,
  body.layout-fluid footer,
  body.layout-fluid div.wrapper {
    width: 98.02956%; }
  body.layout-fluid.two-sidebars.columns-middle .sidebar.first {
    width: 31.5508%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-right .sidebar.first,
  body.layout-fluid.two-sidebars.columns-left .sidebar.first,
  body.layout-fluid.two-sidebars.columns-left .sidebar.second,
  body.layout-fluid.two-sidebars.columns-right .sidebar.second {
    width: 48.6631%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-middle .content.column {
    width: 65.7754%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-right .content.column,
  body.layout-fluid.two-sidebars.columns-middle .sidebar.second,
  body.layout-fluid.two-sidebars.columns-left .content.column {
    width: 100%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right .content.column,
  body.layout-fluid.one-sidebar.columns-middle .content.column,
  body.layout-fluid.one-sidebar.columns-left .content.column {
    width: 65.7754%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-middle .sidebar.second,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.second {
    width: 31.5508%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right .sidebar.first,
  body.layout-fluid.one-sidebar.columns-right .sidebar.second {
    width: 31.5508%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right.sidebar-first .content.column, body.layout-fluid.one-sidebar.columns-middle.sidebar-second .content.column {
    width: 65.7754%;
    margin-right: 0; }
  body.layout-fluid.one-sidebar.columns-right.sidebar-first .sidebar.second, body.layout-fluid.one-sidebar.columns-middle.sidebar-second .sidebar.second {
    width: 31.5508%;
    margin-right: 0; }
  body.layout-fluid.no-sidebars.columns-middle .content.column,
  body.layout-fluid.no-sidebars.columns-right .content.column,
  body.layout-fluid.no-sidebars.columns-left .content.column {
    width: 100%;
    margin-right: 0; } }

@media screen and (min-width: 480px) and (max-width: 767px) {
  body.show-responsive-identifier .responsive-identifier {
    background: #27AE60; }
    body.show-responsive-identifier .responsive-identifier:after {
      content: "S - MOBILE LANDSCAPE"; }
  body.layout-adaptive.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 47, 'gutterWidth' : 12, 'columns' : 8, 'masterContextWidth': 1218}"; }
    body.layout-adaptive.show-grid div#svg-grid-background svg {
      width: 460px; }
  body.layout-adaptive nav,
  body.layout-adaptive footer,
  body.layout-adaptive header,
  body.layout-adaptive div.wrapper {
    width: 460px; }
  body.layout-adaptive.no-sidebars.columns-middle .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-middle .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-middle .content.column, body.layout-adaptive.no-sidebars.columns-right .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-right .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-right .content.column, body.layout-adaptive.no-sidebars.columns-left .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-left .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-left .content.column, body.layout-adaptive.one-sidebar.columns-middle .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-middle .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-middle .content.column, body.layout-adaptive.one-sidebar.columns-right .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-right .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-right .content.column, body.layout-adaptive.one-sidebar.columns-left .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-left .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-left .content.column, body.layout-adaptive.two-sidebars.columns-middle .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-middle .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-middle .content.column, body.layout-adaptive.two-sidebars.columns-right .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-right .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-right .content.column, body.layout-adaptive.two-sidebars.columns-left .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-left .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-left .content.column {
    width: 460px;
    margin-right: 0; }
  body.layout-fluid.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 47, 'gutterWidth' : 12, 'columns' : 8, 'masterContextWidth': 1218}"; }
    body.layout-fluid.show-grid div#svg-grid-background svg {
      width: 460px; }
  body.layout-fluid nav,
  body.layout-fluid header,
  body.layout-fluid footer,
  body.layout-fluid div.wrapper {
    width: 98.68637%; }
  body.layout-fluid.two-sidebars .sidebar.first,
  body.layout-fluid.two-sidebars .sidebar.second,
  body.layout-fluid.two-sidebars .content.column {
    width: 100%;
    margin-right: 0; }
  body.layout-fluid.two-sidebars.columns-middle .sidebar.second {
    position: relative;
    right: auto; }
  body.layout-fluid.no-sidebars.columns-right .sidebar.second,
  body.layout-fluid.no-sidebars.columns-right .sidebar.first,
  body.layout-fluid.no-sidebars.columns-right .content.column,
  body.layout-fluid.no-sidebars.columns-middle .content.column,
  body.layout-fluid.no-sidebars.columns-left .content.column,
  body.layout-fluid.no-sidebars.columns-middle .sidebar.second,
  body.layout-fluid.no-sidebars.columns-middle .sidebar.first,
  body.layout-fluid.no-sidebars.columns-left .sidebar.first,
  body.layout-fluid.no-sidebars.columns-left .sidebar.second, body.layout-fluid.one-sidebar.columns-right .sidebar.second,
  body.layout-fluid.one-sidebar.columns-right .sidebar.first,
  body.layout-fluid.one-sidebar.columns-right .content.column,
  body.layout-fluid.one-sidebar.columns-middle .content.column,
  body.layout-fluid.one-sidebar.columns-left .content.column,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.second,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.second {
    width: 100%;
    margin-right: 0; } }

@media screen and (min-width: 0px) and (max-width: 479px) {
  body.show-responsive-identifier .responsive-identifier {
    background: #F1C40F; }
    body.show-responsive-identifier .responsive-identifier:after {
      content: "XS - MOBILE PORTRAIT"; }
  body.layout-adaptive.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 27, 'gutterWidth' : 12, 'columns' : 8, 'masterContextWidth': 1218}"; }
    body.layout-adaptive.show-grid div#svg-grid-background svg {
      width: 300px; }
  body.layout-adaptive nav,
  body.layout-adaptive footer,
  body.layout-adaptive header,
  body.layout-adaptive div.wrapper {
    width: 300px; }
  body.layout-adaptive.no-sidebars.columns-middle .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-middle .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-middle .content.column, body.layout-adaptive.no-sidebars.columns-right .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-right .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-right .content.column, body.layout-adaptive.no-sidebars.columns-left .sidebar.first,
  body.layout-adaptive.no-sidebars.columns-left .sidebar.second,
  body.layout-adaptive.no-sidebars.columns-left .content.column, body.layout-adaptive.one-sidebar.columns-middle .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-middle .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-middle .content.column, body.layout-adaptive.one-sidebar.columns-right .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-right .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-right .content.column, body.layout-adaptive.one-sidebar.columns-left .sidebar.first,
  body.layout-adaptive.one-sidebar.columns-left .sidebar.second,
  body.layout-adaptive.one-sidebar.columns-left .content.column, body.layout-adaptive.two-sidebars.columns-middle .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-middle .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-middle .content.column, body.layout-adaptive.two-sidebars.columns-right .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-right .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-right .content.column, body.layout-adaptive.two-sidebars.columns-left .sidebar.first,
  body.layout-adaptive.two-sidebars.columns-left .sidebar.second,
  body.layout-adaptive.two-sidebars.columns-left .content.column {
    width: 300px;
    margin-right: 0; }
  body.layout-fluid.show-grid div#svg-grid-background {
    font-family: "{ 'columnWidth' : 27, 'gutterWidth' : 12, 'columns' : 8, 'masterContextWidth': 1218}"; }
    body.layout-fluid.show-grid div#svg-grid-background svg {
      width: 300px; }
  body.layout-fluid nav,
  body.layout-fluid header,
  body.layout-fluid footer,
  body.layout-fluid div.wrapper {
    min-width: 300px;
    width: 98.68637%; }
  body.layout-fluid.two-sidebars .sidebar.first,
  body.layout-fluid.two-sidebars .sidebar.second,
  body.layout-fluid.two-sidebars .content.column {
    width: 100%;
    margin-right: 0; }
  body.layout-fluid.columns-middle .sidebar.second {
    position: relative;
    right: auto; }
  body.layout-fluid.no-sidebars.columns-right .sidebar.second,
  body.layout-fluid.no-sidebars.columns-right .sidebar.first,
  body.layout-fluid.no-sidebars.columns-right .content.column,
  body.layout-fluid.no-sidebars.columns-middle .content.column,
  body.layout-fluid.no-sidebars.columns-left .content.column,
  body.layout-fluid.no-sidebars.columns-middle .sidebar.second,
  body.layout-fluid.no-sidebars.columns-middle .sidebar.first,
  body.layout-fluid.no-sidebars.columns-left .sidebar.first,
  body.layout-fluid.no-sidebars.columns-left .sidebar.second, body.layout-fluid.one-sidebar.columns-right .sidebar.second,
  body.layout-fluid.one-sidebar.columns-right .sidebar.first,
  body.layout-fluid.one-sidebar.columns-right .content.column,
  body.layout-fluid.one-sidebar.columns-middle .content.column,
  body.layout-fluid.one-sidebar.columns-left .content.column,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.second,
  body.layout-fluid.one-sidebar.columns-middle .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.first,
  body.layout-fluid.one-sidebar.columns-left .sidebar.second {
    width: 100%;
    margin-right: 0; } }

.tabs ul.primary:after,
.tabs-container ul.primary:after {
  clear: both;
  content: '';
  display: table; }

input:focus, a:focus, button:focus, textarea:focus, input[type='radio']:focus + label, input[type='checkbox']:focus + label, label.fileinput.prettify.focus, label.select.prettify.focus {
  outline: 2px solid rgba(255, 165, 0, 0.75);
  outline-offset: 2px; }
  html.no-rgba input:focus, html.no-rgba a:focus, html.no-rgba button:focus, html.no-rgba textarea:focus, html.no-rgba input[type='radio']:focus + label, html.no-rgba input[type='checkbox']:focus + label, html.no-rgba label.fileinput.prettify.focus, html.no-rgba label.select.prettify.focus {
    outline: 2px solid #FFA500; }
  input.hide-outline:focus, a.hide-outline:focus, button.hide-outline:focus, textarea.hide-outline:focus, input[type='radio']:focus + label.hide-outline, input[type='checkbox']:focus + label.hide-outline, label.hide-outline.fileinput.prettify.focus, label.hide-outline.select.prettify.focus {
    outline: none !important; }

p {
  margin: 0 0 12px; }

label[for*='disabled'] {
  color: #becbdc; }

button,
input[type='submit'],
input[type='button'],
a.button,
label.select.prettify {
  background-color: #CB4E4E;
  border: 0;
  border-radius: 0.1875em ;
  box-shadow: inset 0 -0.125em  rgba(0, 0, 0, 0.3);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Titillium", Helvetica, Arial;
  font-style: normal;
  line-height: 1em ;
  padding: 0.3125em 0.5em 0.3125em 0.5em ;
  text-decoration: none;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  vertical-align: baseline;
  margin-right: 0.1875em ; }
  button:hover,
  input[type='submit']:hover,
  input[type='button']:hover,
  a.button:hover,
  label.select.prettify:hover {
    background-color: #d77575;
    text-decoration: none; }
  button:visited,
  input[type='submit']:visited,
  input[type='button']:visited,
  a.button:visited,
  label.select.prettify:visited {
    color: #FFFFFF; }
  button.disabled:hover, button.disabled,
  input[type='submit'].disabled:hover,
  input[type='submit'].disabled,
  input[type='button'].disabled:hover,
  input[type='button'].disabled,
  a.button.disabled:hover,
  label.disabled.select.prettify:hover,
  a.button.disabled,
  label.disabled.select.prettify {
    background-color: white;
    box-shadow: inset 0 -0.0625em  rgba(0, 0, 0, 0.3);
    color: #becbdc;
    pointer-events: none; }


a.button:hover,
label.select.prettify:hover {
  color: #FFFFFF; }

label.select.prettify,
div.password-suggestions,
div.messages,
input, textarea, fieldset {
  border-radius: 0.125em ; }

span.form-required {
  color: #B73939;
  font-weight: bold; }

input, textarea {
  border: 0.0625em  solid #3d5573;
  padding: 0.3125em 0.5em 0.3125em 0.5em ; }
  input.error, textarea.error {
    border: 0.0625em  solid #B73939; }
  .form-item input.error, .form-item textarea.error {
    border: 0.0625em  solid #B73939; }

input[type='file'] {
  padding: 0.0625em 0/pxem 0.0625em 0.0625em ; }

input[disabled] {
  background-color: white;
  border: 0.0625em  solid #f0f3f7; }

fieldset {
  margin-top: 0.625em ;
  padding: 0.1875em 0.625em 0.75em 0.625em ; }
  fieldset legend {
    padding: 0/pxem 0.3125em ; }
  html.js fieldset.collapsible .fieldset-legend {
    background-image: none;
    padding-left: 0; }
    html.js fieldset.collapsible .fieldset-legend:before {
      content: "";
      display: inline-block;
      font-size: 75%;
      height: 14px;
      width: 14px; }
  html.js fieldset.collapsible.collapsed {
    border-left: 0.0625em  solid transparent; }
    html.js fieldset.collapsible.collapsed .fieldset-legend:before {
      transform: rotate(-90deg);
      vertical-align: 3px; }
  html.js fieldset.collapsible a.fieldset-title {
    padding-left: 0.1875em ; }

.password-confirm > span {
  font-weight: bold;
  text-transform: uppercase; }

.password-indicator {
  border-radius: 0.125em ; }
  .password-indicator div {
    background-color: #4D8F46;
    border-radius: 0.125em ; }

div.messages {
  background-image: none;
  border: 0;
  color: #FFFFFF;
  padding: 0.625em ; }
  div.messages .krumo-root {
    color: #000000; }
  div.messages > ul {
    display: inline-block;
    margin: 0; }
  div.messages:before {
    float: left;
    margin-top: 0.0625em ;
    padding-right: 0.625em ; }
  div.messages.warning {
    background-color: #E5A200; }
    div.messages.warning:before {
      content: ""; }
  div.messages.status {
    background-color: #4D8F46; }
    div.messages.status:before {
      content: ""; }
  div.messages.error {
    background-color: #B73939; }
    div.messages.error:before {
      content: ""; }

input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
  color: #007BC7; }
  input[type='checkbox']:checked + label:before,
  input[type='radio']:checked + label:before {
    color: #007BC7; }

input[type='radio'], input[type='checkbox'] {
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: pointer;
  position: absolute; }
  input[type='radio'] + label, input[type='checkbox'] + label {
    cursor: pointer; }
  input[type='radio'] + label:before, input[type='checkbox'] + label:before {
    content: "";
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #f0f3f7;
    margin-right: 5px; }
  input[type='radio'].hide-outline:focus + label, input[type='checkbox'].hide-outline:focus + label {
    outline: none !important; }
  input[type='radio'].error + label:before, input[type='checkbox'].error + label:before {
    color: #B73939; }
  html.lt-ie9 input[type='radio'], html.lt-ie9 input[type='checkbox'] {
    filter: alpha(opacity=100);
    opacity: 1;
    background-color: transparent;
    position: relative; }
    html.lt-ie9 input[type='radio']:focus, html.lt-ie9 input[type='radio']:active, html.lt-ie9 input[type='checkbox']:focus, html.lt-ie9 input[type='checkbox']:active {
      border: 0;
      outline: none; }
    html.lt-ie9 input[type='radio'] + label:before, html.lt-ie9 input[type='checkbox'] + label:before {
      content: '';
      display: none; }

input[type='checkbox']:checked + label:before {
  content: ""; }

input[type='radio']:checked + label:before {
  content: ""; }

label.fileinput.prettify {
  border: 0.0625em  solid #3d5573;
  border-radius: 0.125em ;
  display: inline-block;
  font-weight: normal;
  height: 1.875em ;
  min-width: 280px;
  position: relative; }
  label.fileinput.prettify input[type='file'] {
    max-width: 280px; }
  label.fileinput.prettify.error {
    border: 0.0625em  solid #B73939; }
  label.fileinput.prettify.disabled {
    background-color: white;
    border: 0.0625em  solid #f0f3f7;
    color: #becbdc;
    pointer-events: none; }
    label.fileinput.prettify.disabled a.button, label.fileinput.prettify.disabled label.select.prettify {
      background-color: white;
      box-shadow: inset 0 -0.0625em  rgba(0, 0, 0, 0.3);
      color: #becbdc;
      pointer-events: none; }
  label.fileinput.prettify > div {
    display: table;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 100%; }
    label.fileinput.prettify > div > span {
      display: table-cell; }
    label.fileinput.prettify > div span.filetext {
      color: #becbdc;
      max-width: 100px;
      overflow: hidden;
      padding: 0/pxem 0.5em ;
      text-overflow: ellipsis;
      width: 100%; }
      label.fileinput.prettify > div span.filetext.selected {
        color: inherit; }

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 0; }

label.select.prettify {
  background-color: #0E83CD;
  display: inline-block;
  font-weight: normal;
  margin-right: 0;
  position: relative; }
  label.select.prettify.error:after {
    border: 0.0625em  solid #B73939;
    border-radius: 0.125em ;
    bottom: -2px;
    content: '';
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px; }
  label.select.prettify.error.focus {
    outline-offset: 4px; }
  label.select.prettify:hover {
    background-color: #1e9ff0;
    box-shadow: inset 0 -0.125em  #0E83CD; }
  label.select.prettify select {
    border: 1px solid transparent;
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3; }
  label.select.prettify span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    label.select.prettify span:after {
      content: "";
      font-size: 70%;
      padding-left: 0.625em ;
      vertical-align: 0.0625em ; }
  html.lt-ie9 label.select.prettify option {
    width: 100%; }

a {
  color: #3d5573;
  text-decoration: none;
  transition: color 0.1s ease-in-out; }
  a:hover {
    color: #3d5573;
    text-decoration: underline; }

body ul {
  margin: 0 0 1em 0; }
  body ul li > ul {
    margin: 0 0 0 1em; }

ol {
  counter-reset: olcounter;
  list-style-position: inside;
  margin: 0 0 1em 0;
  padding: 0; }
  ol li {
    list-style-type: none; }
    ol li:before {
      content: counter(olcounter) ".";
      counter-increment: olcounter;
      font-family: "Titillium", Helvetica, Arial;
      font-size: 16px;
      padding: 0 4px; }
    ol li > ol {
      margin: 0 0 0 1em; }

.fairtrade-logo {
  position: absolute;
  right: -80px;
  top: 80px;
  z-index: 20; }
  .fairtrade-logo img {
    width: 200px; }

.content .body table {
  min-width: 475px;
  border: 0; }
  .content .body table tr {
    border-bottom: solid 1px #c4d0df; }
    .content .body table tr td {
      padding: 4px;
      border: 0; }

div.streamer {
  background: #adbdd3;
  padding: 16px; }
  div.streamer h2 {
    font-family: 'Quicksand Light Regular';
    font-size: 2em; }
  div.streamer table td {
    vertical-align: top; }
  div.streamer table td.col-first {
    padding-right: 16px; }

#block-pknokw_blocks-foodbank {
  margin-top: 10px; }
  #block-pknokw_blocks-foodbank .foodbank {
    background-color: #adbdd3;
    margin: 50px 0; }
    #block-pknokw_blocks-foodbank .foodbank p {
      padding: 10px 10px 20px;
      font-size: 16px; }
    #block-pknokw_blocks-foodbank .foodbank h2 {
      background-color: #3d5573;
      color: #c4d0df;
      font-family: Quicksand;
      font-size: 28px;
      line-height: 1em;
      padding: 10px;
      text-align: center; }

html, body {
  font-family: "Titillium", Helvetica, Arial;
  height: 100%;
  line-height: 1.5em ; }

a {
  text-decoration: none; }

li.node-readmore a:after {
  font-size: 50%;
  vertical-align: 1px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Titillium", Helvetica, Arial;
  margin: 0 0 12px; }

div.streamers {
  clear: both;
  margin: 0 auto; }

header,
div.wrapper,
div.streamers,
footer {
  background: #c4d0df;
  border-left: solid 1px #acb4bf;
  border-right: solid 1px #acb4bf;
  position: relative; }

div.streamers,
.logowrap {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto; }

section.content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 45px 0;
  position: relative; }
  section.content .content-wrapper {
    background: #f0f3f7;
    margin: 0 45px 0 25px;
    padding: 45px;
    width: 100%; }

.logowrap {
  margin-top: -209px;
  position: relative; }
  .logowrap .logo {
    line-height: 0; }

.name-slogan {
  background: rgba(0, 0, 0, 0.5);
  font-family: 'Quicksand Light Regular';
  font-size: 3em;
  line-height: 71px;
  text-align: center;
  text-transform: uppercase; }
  .name-slogan a {
    color: #FFFFFF; }

footer {
  background: #3d5573;
  height: 64px; }
  footer p {
    color: #adbdd3;
    line-height: 64px;
    margin: 0;
    text-align: center; }
    footer p a.insta {
      color: #adbdd3; }
      footer p a.insta:before {
        content: "";
        font-size: 16px;
        padding: 0 7px; }

nav ul {
  margin: 0;
  padding: 0; }
  nav ul li {
    display: inline-block;
    font-size: 100%; }

.sidebar ul.menu {
  border-bottom: solid 1px #162b47;
  font-family: 'Quicksand Book Regular';
  font-size: 24px;
  line-height: 40px;
  list-style-type: none;
  margin: 0;
  min-height: 36px;
  text-transform: uppercase; }
  .sidebar ul.menu li {
    border-bottom: solid 1px #162b47; }
    .sidebar ul.menu li.first {
      border-top: solid 1px #162b47; }
    .sidebar ul.menu li.last {
      border-bottom: solid 0 #162b47; }
    .sidebar ul.menu li.leaf {
      list-style-image: none;
      list-style-type: none;
      margin: 0;
      padding: 2px 10px; }

.menu-block-1,
.submenu {
  float: right;
  margin-bottom: 20px;
  margin-left: 8px; }
  .menu-block-1 ul.menu li,
  .submenu ul.menu li {
    border-bottom: solid 1px #162b47;
    font-family: 'Quicksand Book Regular';
    font-size: 18px;
    line-height: 28px;
    list-style-type: none;
    margin: 0;
    min-height: 36px;
    padding-left: 10px;
    text-transform: uppercase;
    width: 250px; }
    .menu-block-1 ul.menu li.first,
    .submenu ul.menu li.first {
      border-top: solid 1px #162b47; }
    .menu-block-1 ul.menu li a,
    .submenu ul.menu li a {
      color: #162b47; }

li a.active-trail {
  font-weight: bold; }

.menu .active-trail .a {
  font-weight: bold; }

aside.sidebar {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1; }
  aside.sidebar p {
    margin: 0; }
  aside.sidebar.second h2 {
    font-weight: normal; }
  aside.sidebar #search-block-form {
    display: block;
    margin: 24px 12px; }
    aside.sidebar #search-block-form .form-item-search-block-form label {
      display: none; }
  aside.sidebar .listen-services img {
    width: 100%; }

.tabs,
.tabs-container {
  margin: 20px 0 0; }
  .tabs nav,
  .tabs-container nav {
    width: 100%; }
  .tabs ul.primary,
  .tabs-container ul.primary {
    border-bottom: 2px solid #c0c0c0;
    margin: 0; }
    .tabs ul.primary li,
    .tabs-container ul.primary li {
      display: inline-block; }
      .tabs ul.primary li a,
      .tabs-container ul.primary li a {
        background: #eceff4;
        border: 2px solid #c0c0c0;
        border-bottom: 0;
        color: #cc3700;
        display: block;
        font-size: 16px;
        padding: 4px 10px; }
        .tabs ul.primary li a:hover,
        .tabs-container ul.primary li a:hover {
          background: #f5f5f5; }
        .tabs ul.primary li a.active,
        .tabs-container ul.primary li a.active {
          background: #f5f5f5;
          border-bottom: 0; }
        .tabs ul.primary li a .element-invisible,
        .tabs-container ul.primary li a .element-invisible {
          position: absolute !important; }

body.ft-tabs-rendered .wrapper .tabs-container {
  display: none; }

body.ft-tabs-rendered div.ft-collapsible-tabs {
  background-color: #f0f3f7;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
  height: 54px;
  position: fixed;
  right: 0;
  top: 200px;
  transition: width 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: 54px;
  z-index: 99; }
  body.ft-tabs-rendered div.ft-collapsible-tabs .ft-tab-clicker {
    display: inline-block;
    padding: 10px;
    position: absolute;
    right: 0;
    transition: right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    body.ft-tabs-rendered div.ft-collapsible-tabs .ft-tab-clicker:before {
      content: "";
      color: #3d5573;
      cursor: pointer;
      font-size: 32px; }
  body.ft-tabs-rendered div.ft-collapsible-tabs nav {
    border-radius: 0 0 0 10px;
    margin: 0;
    overflow: hidden;
    position: absolute;
    right: -300px;
    text-align: right;
    top: 0;
    transition: right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 300px; }
    body.ft-tabs-rendered div.ft-collapsible-tabs nav ul {
      border: 0; }
      body.ft-tabs-rendered div.ft-collapsible-tabs nav ul li {
        display: block; }
        body.ft-tabs-rendered div.ft-collapsible-tabs nav ul li a {
          background-color: #f0f3f7;
          border: 0;
          color: #3d5573;
          transition: background-color 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
          body.ft-tabs-rendered div.ft-collapsible-tabs nav ul li a:hover {
            background-color: #eceff4; }
  body.ft-tabs-rendered div.ft-collapsible-tabs.ft-tabs-open {
    width: 354px; }
    body.ft-tabs-rendered div.ft-collapsible-tabs.ft-tabs-open .ft-tab-clicker {
      right: 300px; }
    body.ft-tabs-rendered div.ft-collapsible-tabs.ft-tabs-open nav {
      right: 0; }

html body.ft-layout-login.page-user {
  color: #666666;
  height: 100vh;
  min-height: 600px; }
  html body.ft-layout-login.page-user .login-bg,
  html body.ft-layout-login.page-user .login-overlay {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  html body.ft-layout-login.page-user .login-bg {
    background: url("../images/login/login-bg.jpg") no-repeat center center fixed;
    background-size: cover; }
  html body.ft-layout-login.page-user .login-overlay {
    background-color: rgba(72, 155, 224, 0.75); }
  html body.ft-layout-login.page-user header *,
  html body.ft-layout-login.page-user footer,
  html body.ft-layout-login.page-user .column.sidebar,
  html body.ft-layout-login.page-user .form-required,
  html body.ft-layout-login.page-user .breadcrumb {
    display: none; }
  html body.ft-layout-login.page-user .wrapper {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%; }
    html body.ft-layout-login.page-user .wrapper #page-title {
      display: none; }
    html body.ft-layout-login.page-user .wrapper .content.column {
      float: none;
      margin: 0 auto;
      max-width: calc(100% - 40px);
      padding-bottom: 60px;
      position: relative;
      width: 350px; }
      html body.ft-layout-login.page-user .wrapper .content.column .ft-logo {
        margin-bottom: 50px;
        text-align: center; }
        html body.ft-layout-login.page-user .wrapper .content.column .ft-logo img {
          display: inline-block; }
      html body.ft-layout-login.page-user .wrapper .content.column .tabs {
        bottom: 0;
        position: absolute;
        width: 100%; }
        html body.ft-layout-login.page-user .wrapper .content.column .tabs ul {
          border: 0;
          margin: 0;
          padding: 0;
          text-align: center; }
          html body.ft-layout-login.page-user .wrapper .content.column .tabs ul li a {
            background: transparent;
            border: 0;
            color: #FFFFFF;
            display: inline;
            font-size: 12px;
            margin: 0;
            padding: 0; }
          html body.ft-layout-login.page-user .wrapper .content.column .tabs ul li:after {
            color: #FFFFFF;
            content: ' | '; }
          html body.ft-layout-login.page-user .wrapper .content.column .tabs ul li:last-child:after {
            content: ''; }
      html body.ft-layout-login.page-user .wrapper .content.column form {
        background: #FFFFFF; }
        html body.ft-layout-login.page-user .wrapper .content.column form .form-item {
          margin: 0;
          padding: 50px 50px 0; }
          html body.ft-layout-login.page-user .wrapper .content.column form .form-item .form-text {
            border: 0;
            border-bottom: 1px solid #e5e5e5;
            border-radius: 0;
            font-size: 16px;
            height: 45px;
            padding: 8px 6px;
            vertical-align: middle;
            width: 100%; }
            html body.ft-layout-login.page-user .wrapper .content.column form .form-item .form-text:focus {
              outline: 0; }
        html body.ft-layout-login.page-user .wrapper .content.column form .description {
          font-size: 12px;
          font-style: italic;
          margin: 50px 50px 0; }
        html body.ft-layout-login.page-user .wrapper .content.column form .form-actions {
          padding-top: 50px; }
          html body.ft-layout-login.page-user .wrapper .content.column form .form-actions .form-submit {
            background-color: #489BE0;
            border: 0;
            border-radius: 0;
            color: #FFFFFF;
            height: 50px;
            text-transform: uppercase;
            transition: background-color 0.4s;
            width: 100%; }
            html body.ft-layout-login.page-user .wrapper .content.column form .form-actions .form-submit:hover {
              background-color: #1c67a6; }

.front section.content {
  padding: 5px; }
  .front section.content .views-field-title {
    display: none; }
  .front section.content h2 {
    font-weight: 100; }

.node-type-fotoalbums .content .image {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -13px; }
  .node-type-fotoalbums .content .image a {
    margin: 13px; }

.paragraphs-item-afbeelding-plus-tekst .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .paragraphs-item-afbeelding-plus-tekst .content .titel {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    width: 100%; }
  .paragraphs-item-afbeelding-plus-tekst .content div.image {
    display: inline-block;
    flex: 1;
    margin: 0 22px 22px 0; }
  .paragraphs-item-afbeelding-plus-tekst .content div.tekst {
    display: inline-block;
    flex: 3; }

.paragraphs-item-afbeelding-plus-tekst.img_left .content div.tekst {
  order: 2; }

.paragraphs-item-afbeelding-plus-tekst.img_left .content div.image {
  order: 1; }

.paragraphs-item-afbeelding-plus-tekst.img_right .content div.tekst {
  order: 1; }

.paragraphs-item-afbeelding-plus-tekst.img_right .content div.image {
  margin: 0 0 22px 22px;
  order: 2; }

.paragraphs-item-tekst .titel {
  display: inline-block;
  font-size: 24px;
  font-weight: bold; }

.paragraphs-item-2-images .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .paragraphs-item-2-images .content .titel {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    width: 100%; }
  .paragraphs-item-2-images .content div.image {
    display: inline-block;
    flex: 1;
    margin: 0 22px 22px 0; }
  .paragraphs-item-2-images .content div.promo {
    display: inline-block;
    flex: 1;
    margin: 0; }

.paragraphs-item-2-images.img_left .content div.promo {
  order: 2; }

.paragraphs-item-2-images.img_left .content div.image {
  order: 1; }

.paragraphs-item-2-images.img_right .content div.promo {
  margin: 0 22px 22px 0;
  order: 1; }

.paragraphs-item-2-images.img_right .content div.image {
  margin: 0;
  order: 2; }

.paragraphs-item-3-images .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .paragraphs-item-3-images .content .titel {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    width: 100%; }
  .paragraphs-item-3-images .content div.multi-images {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .paragraphs-item-3-images .content div.multi-images a {
      display: block;
      margin-bottom: 22px; }

.paragraphs-item-full-image .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .paragraphs-item-full-image .content .titel {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    width: 100%; }
  .paragraphs-item-full-image .content div.image {
    display: inline-block;
    flex: 1; }
