aside.sidebar {
  background-color: transparentize($white, .5);
  z-index: 1;

  p {
    margin: 0;
  }

  &.second h2 {
    font-weight: normal;
  }

  #search-block-form {
    display: block;
    margin: 24px 12px;

    .form-item-search-block-form {
      label {
        display: none;
      }

    }


  }

  .listen-services {
    img {
      width: 100%;
    }
  }


}
