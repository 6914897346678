#block-pknokw_blocks-foodbank {
  margin-top: 10px;

  .foodbank {
    background-color: $linkwater;
    margin: 50px 0;

    p {
      padding: 10px 10px 20px;
      font-size: 16px;
    }

    h2 {
      background-color: $chathamsblue;
      color: $lochmara;
      font-family: Quicksand;
      font-size: 28px;
      line-height: 1em;
      padding: 10px;
      text-align: center;
    }
  }
}
