.paragraphs-item-full-image {

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .titel {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      //min-width: 475px;
      width: 100%;
    }

    div.image {
      display: inline-block;
      flex: 1;
    }
  }
}
