div.streamer {
  background: $linkwater;
  padding: 16px;

  h2 {
    font-family: 'Quicksand Light Regular';
    font-size: 2em;
  }

  table {
    td {
      vertical-align: top;
    }

    td.col-first {
      padding-right: 16px;
    }
  }

}
