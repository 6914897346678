// sass-lint:disable no-important

// ADDITIONS
*, *:before, *:after {
  box-sizing: border-box;
}

ul {
  list-style: inside;
  margin: 0;
  padding: 0;

  &.menu {
    list-style: inside;
  }

  li {
    list-style-image: none;
    list-style-type: disc;

    &.leaf,
    &.collapsed {
      list-style-image: none;
      list-style-type: disc;
    }
  }
}

.element-invisible {
  display: table-column;
  position: relative !important;
}

body {
  color: $page-font-color;
  font-size: $default-font-size;
  margin: 0;
}

//--------------------------------------------------------------------------------------//

// HTML5 display definitions

// Correct `block` display not defined in IE 8/9.
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

// Correct `inline-block` display not defined in IE 8/9.
audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  // Prevent modern browsers from displaying `audio` without controls.
  display: none;

  // Remove excess height in iOS 5 devices.
  height: 0;
}

// Address styling not present in IE 8/9.
[hidden] {
  display: none;
}

// Base

html {
  // Prevent system color scheme's background color being used in Firefox, IE, and Opera.
  background-color: $page-background-color;

  // Prevent system color scheme's text color being used in Firefox, IE, and Opera.
  color: $page-font-color;

  // Set default font family to sans-serif.
  font-family: sans-serif;
}

// Links

a {
  // Address `outline` inconsistency between Chrome and other browsers.
  &:focus {
    outline: thin dotted;
  }

  // Improve readability when focused and also mouse hovered in all browsers.
  &:active, &:hover {
    outline: 0;
  }
}

// Typography

// Address variable `h1` font-size and margin within `section` and `article` contexts in Firefox 4+, Safari 5, and Chrome.
h1 {
  font-size: $default-font-size * 2;
  margin: 0.67em 0;
}

// Address styling not present in IE 8/9, Safari 5, and Chrome.
abbr[title] {
  border-bottom: 1px dotted;
}

// Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
b, strong {
  font-weight: bold;
}

p {
  margin: 1em 0;
}

// Address styling not present in Safari 5 and Chrome.
dfn {
  font-style: italic;
}

// Address differences between Firefox and other browsers.
hr {
  box-sizing: content-box;
  height: 0;
}

// Address styling not present in IE 8/9.
mark {
  background-color: $mark-background-color;
  color: $mark-font-color;
}

// Correct font family set oddly in Safari 5 and Chrome.
code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: $default-font-size;
}

// Improve readability of pre-formatted text in all browsers.
pre {
  white-space: pre-wrap;
}

// Set consistent quote types.
q {
  quotes: '“' '”' '‘' '’';
}

// Address inconsistent and variable font size in all browsers.
small {
  font-size: 80%;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Embedded content

// Remove border when inside `a` element in IE 8/9.
img {
  border: 0;
  display: block;
}

// Correct overflow displayed oddly in IE 9.
svg:not(:root) {
  overflow: hidden;
}

// Figures

// Address margin not present in IE 8/9 and Safari 5.
figure {
  margin: 0;
}

// Forms

// Define consistent border, margin, and padding.
fieldset {
  border: 1px solid $silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  // Correct `color` not being inherited in IE 8/9.
  border: 0;

  // Remove padding so people aren't caught out if they zero out fieldsets.
  padding: 0;
}

button, input, select, textarea {
  // Correct font family not being inherited in all browsers.
  font-family: inherit;

  // Correct font size not being inherited in all browsers.
  font-size: 100%;

  // Address margins set differently in Firefox 4+, Safari 5, and Chrome.
  margin: 0;
}

// Address Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet.
button, input {
  line-height: normal;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
// Correct `select` style inheritance in Firefox 4+ and Opera.
button, select {
  text-transform: none;
}

button, html input[type='button'], input[type='reset'], input[type='submit'] {
  // Improve usability and consistency of cursor style between image-type `input` and others.
  cursor: pointer;
}

// Re-set default cursor for disabled elements.
button[disabled], html input[disabled] {
  cursor: default;
}

input {
  &[type='checkbox'], &[type='radio'] {
    // Address box sizing set to `content-box` in IE 8/9.
    box-sizing: border-box;

    // Remove excess padding in IE 8/9.
    padding: 0;
  }

  &[type='search'] {
    // Address `box-sizing` set to `border-box` in Safari 5 and Chrome (include `-moz` to future-proof).
    box-sizing: content-box;
  }
}

textarea {
  // Remove default vertical scrollbar in IE 8/9.
  overflow: auto;

  // Improve readability and alignment in all browsers.
  vertical-align: top;
}

// Tables

// Remove most spacing between table cells.
table {
  border-collapse: collapse;
  border-spacing: 0;
}
