nav ul {
  @include inline-list;
}

.sidebar ul.menu {
  border-bottom: solid 1px $navy;
  font-family: 'Quicksand Book Regular';
  font-size: 24px;
  line-height: 40px;
  list-style-type: none;
  margin: 0;
  min-height: 36px;
  //padding-left: 10px;
  text-transform: uppercase;

  li {
    border-bottom: solid 1px $navy;

    &.first {
      border-top: solid 1px $navy;
    }

    &.last {
      border-bottom: solid 0 $navy;
    }

    &.leaf {
      list-style-image: none;
      list-style-type: none;
      margin: 0;
      padding: 2px 10px;
    }
  }
}
.menu-block-1,
.submenu {
  float: right;
  //top: 20px;
  margin-bottom: 20px;
  margin-left: 8px;

  ul.menu li {
    border-bottom: solid 1px $navy;
    font-family: 'Quicksand Book Regular';
    font-size: 18px;
    line-height: 28px;
    list-style-type: none;
    margin: 0;
    min-height: 36px;
    padding-left: 10px;
    text-transform: uppercase;
    width: 250px;

    &.first {
      border-top: solid 1px $navy;
    }

    a {
      color: $navy;
    }

  }

}

li a.active-trail {
  font-weight: bold;
}

.menu .active-trail .a {
  font-weight: bold;
}
